<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <base-material-card>
      <template v-slot:heading>
        <div class="text-h3 font-weight-light">
          Ajouter une promotion
        </div>
      </template>

      <v-form>
        <v-container class="py-0">
          <v-row>
            <v-col
              cols="6"
              md="3"
              offset-md="2"
            >
              <v-text-field
                v-model="nouveauCodePromotion.nomCodePromotion"
                label="Code de la promotion"
                class="purple-input"
              />
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="nouveauCodePromotion.montantCodePromotion"
                label="Montant"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="4"
              offset-md="2"
            >
              Date de Début :
              <input
                v-model="nouveauCodePromotion.date_debut"
                type="date"
              >
            </v-col>
            <v-col
              cols="12"
              md="5"
            >
              Date de Fin :
              <input
                v-model="nouveauCodePromotion.date_fin"
                type="date"
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col
              offset-md="2"
            >
              <v-btn
                color="info"
                class="mr-0"
                @click="ajouterCodePromo()"
              >
                <v-icon
                  class="mr-2"
                >
                  mdi-alarm-plus
                </v-icon>
                Ajouter
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </base-material-card>
    <v-row>
      <v-col
        cols="12"
      >
        <base-material-card
          title="Liste des promotions"
        >
          <template>
            <v-row
              class="align-center"
            >
              <v-col
                cols="12"
                md="5"
              >
                <v-text-field
                  label="Rechercher"
                  style=""
                >
                  <template
                    v-if="$vuetify.breakpoint.mdAndUp"
                    v-slot:append-outer
                  >
                    <v-btn
                      class=""
                      elevation=""
                      small
                    >
                      <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="3"
                offset-md="1"
              >
                <v-btn
                  color="secondary"
                  @click="trierstatut()"
                >
                  <v-icon left>
                    mdi-state-machine
                  </v-icon>
                  Trier par statut<!-- 1 clic: ordre alpha 2 clics: ordre inverse 3 clics: pas de tri -->
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-btn
                  color="success"
                  @click="trierDate()"
                >
                  <v-icon left>
                    mdi-sort-calendar-ascending
                  </v-icon>
                  Trier par date
                </v-btn>
              </v-col>
            </v-row>
            <div class="text-h3 font-weight-light">
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="primary--text">
                      Id
                    </th>
                    <th class="primary--text">
                      Code
                    </th>
                    <th class="primary--text">
                      montant
                    </th>
                    <th class="primary--text">
                      Date de début
                    </th>
                    <th class="primary--text">
                      Date de fin
                    </th>
                    <th class="primary--text">
                      statut
                    </th>
                    <th class="primary--text">
                      action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="codePromotion in codePromotions"
                    :key="codePromotion.id_promotion"
                  >
                    <td> {{ codePromotion.id_promotion }} </td>
                    <td> {{ codePromotion.nom }} </td>
                    <td> {{ codePromotion.montant }} </td>
                    <td> {{ codePromotion.date_debut }} </td>
                    <td> {{ codePromotion.date_fin }} </td>
                    <td> {{ codePromotion.statut }} </td>
                    <td>
                      <!--                      <v-btn-->
                      <!--                        disabled-->
                      <!--                        color="success"-->
                      <!--                        @click="GoDetailspromotion(codePromotion)"-->
                      <!--                      >-->
                      <!--                        modifier-->
                      <!--                      </v-btn>-->
                      <v-btn
                        color="success"
                        @click="DesactiverCodePromo(codePromotion)"
                      >
                        Supprimer
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </template>
        </base-material-card>
      </v-col>
    </v-row>
    <v-row>
      <base-material-snackbar
        v-model="updateOk"
        :type="colorSnackbarSuccess"
        v-bind="{
          [parsedDirection[0]]: true,
          [parsedDirection[1]]: true
        }"
      >
        <span class="font-weight-bold"> Le code promo a bien été ajouter.</span>
      </base-material-snackbar>

      <base-material-snackbar
        v-model="KOupdate"
        :type="colorSnackbarError"
        v-bind="{
          [parsedDirection[0]]: true,
          [parsedDirection[1]]: true
        }"
      >
        <span class="font-weight-bold"> Erreur lors de l'ajout du code Promo'</span>
      </base-material-snackbar>
    </v-row>
  </v-container>
</template>

 <script>
  // Utilities
  import axios from 'axios'
  import VueAxios from 'vue-axios'
  import Vue from 'vue'
  import {
    mapState,
  } from 'vuex'

  Vue.use(VueAxios, axios)

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      websiteid: '',
      date_debut: '',
      date_fin: '',
      nouveauCodePromotion: [],
      codePromotion: '',
      montantChoisi: '',
      updateOk: false,
      KOupdate: false,
      colorSnackbarSuccess: '',
      colorSnackbarError: '',
      direction: 'bottom right',
      codePromotions: [],
      status: [{
        statutId: 1,
        statutLabel: '1 en attente de paiements',
      }, {
        statutId: 2,
        statutLabel: 'Payé',
      }, {
        statutId: 3,
        statutLabel: 'Annulé',
      }, {
        statutId: 4,
        statutLabel: 'Supprimé',
      }, {
        statutId: 5,
        statutLabel: 'Remboursé',
      },
      ],
    }),
    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      parsedDirection () {
        return this.direction.split(' ')
      },
      profile () {
        return {
          avatar: true,
          title: this.$t('site_title'),
        }
      },
    },
    mounted () {
      this.websiteid = this.$cookie.get('website_id')
      Vue.axios.get('https://api.espace-jantes.com/getPromotions', { params: { websiteid: this.websiteid } })
        .then((response) => {
          response.data.forEach(element => {
            element.date_debut = this.getFormatedDate(element.date_debut)
            element.date_fin = this.getFormatedDate(element.date_fin)
            this.codePromotions.push(element)
          })
        })
        .catch(error => console.log(error))
    },
    methods: {
      getFormatedDate: function (dateFromElement) {
        var splitDateTime = dateFromElement.split('T')
        var splitDate = splitDateTime[0].split('-')
        var date = splitDate[2] + '/' + splitDate[1] + '/' + splitDate[0]
        return date
      },
      ajouterCodePromo: function () {
        if (isNaN(this.nouveauCodePromotion.montantCodePromotion)) {
          this.KOupdate = true
          this.colorSnackbarError = 'error'
          return
        }
        if (this.nouveauCodePromotion.date_fin === 'undefined' || this.nouveauCodePromotion.date_debut === 'undefined' || this.nouveauCodePromotion.date_fin === undefined || this.nouveauCodePromotion.date_debut === undefined) {
          this.KOupdate = true
          this.colorSnackbarError = 'error'
          return
        }

        Vue.axios.post('https://api.espace-jantes.com/addPromotion', {
          params:
            {
              infoCodePromotion: {
                nom: this.nouveauCodePromotion.nomCodePromotion,
                montantPromotion: this.nouveauCodePromotion.montantCodePromotion,
                dateFin: this.nouveauCodePromotion.date_fin,
                dateDebut: this.nouveauCodePromotion.date_debut,
                statut: 1,
              },
              websiteid: this.websiteid,
            },
        })
          .then((response) => {
            if (response.data.etat) {
              this.updateOk = true
              this.colorSnackbarSuccess = 'success'
              this.codePromotions = []
              Vue.axios.get('https://api.espace-jantes.com/getPromotions', { params: { websiteid: this.websiteid } })
                .then((response) => {
                  response.data.forEach(element => {
                    element.date_debut = this.getFormatedDate(element.date_debut)
                    element.date_fin = this.getFormatedDate(element.date_fin)
                    this.codePromotions.push(element)
                  })
                })
                .catch(error => console.log(error))
            } else {
              this.KOUpdate = true
              this.colorSnackbarError = 'error'
            }
          })
          .catch(error => console.log(error))
      },
      GoDetailspromotion (CodePromotion) {
        this.$router.push({ name: 'PromotionDetails', params: { idCodePromotion: CodePromotion.id_promotion } })
      },
      modifierCodePromo (CodePromo) {
        console.log(CodePromo)
      },
      DesactiverCodePromo (CodePromo) {
        console.log(CodePromo)
        Vue.axios.put('https://api.espace-jantes.com/DesactiverCodePromo', {
          params:
            {
              infoCodePromotion: {
                id: CodePromo.id_promotion,
                statut: 2,
              },
            },
        })
          .then((response) => {
            Vue.axios.get('https://api.espace-jantes.com/getPromotions', { params: { websiteid: this.websiteid } })
              .then((response) => {
                response.data.forEach(element => {
                  element.date_debut = this.getFormatedDate(element.date_debut)
                  element.date_fin = this.getFormatedDate(element.date_fin)
                  this.codePromotions.push(element)
                })
              })
              .catch(error => console.log(error))
          })
          .catch(error => console.log(error))
      },
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
    },
  }
</script>
<style scoped>
  input[type=text] {
    border: 2px solid black !important;
    border-radius : 4px;
    margin-right : 15px;
  }
  th {
    font-size: 15px !important
  }
</style>
